import georgesApi from '@/shared/apis/georges';

const PAYMENT_API_BASE_PATH = 'payment';

/**
 * @param {('GET'|'POST')} options.method='GET' options.method
 * @param {string} options.url
 * @param {object} options.data
 * @returns {Promise<object>}
 */
function georgesPaymentApi(options) {
  return georgesApi({ ...options, baseURL: `/api/${PAYMENT_API_BASE_PATH}` });
}

export async function newStripeSubscriptionFromSetupIntent({ setupIntent }) {
  const { data } = await georgesPaymentApi({
    method: 'POST',
    url: `/intent/${setupIntent.id}/subscribe`,
    data: {
      setupIntent,
    },
  });
  return data.result;
}
export async function createSetupIntent() {
  const { data } = await georgesPaymentApi({
    method: 'POST',
    url: '/intent',
  });
  return data.stripeSetupIntent;
}

export async function getLastStripePaymentIntentToConfirm() {
  const { data } = await georgesPaymentApi({
    method: 'POST',
    url: `/intent/confirm`,
  });
  return data;
}

export async function updateDefaultPaymentCredentials({ paymentMethodId }) {
  const { data } = await georgesPaymentApi({
    method: 'POST',
    url: '/payment-credentials',
    data: { paymentMethodId },
  });
  return data.result;
}

export async function cancelStripeSubscription({ churnReason } = { churnReason: undefined }) {
  const { data } = await georgesPaymentApi({
    method: 'POST',
    url: '/cancellation',
    data: {
      churnReason,
    },
  });
  return data.isChurnReasonNeeded;
}

export async function restoreStripeSubscription() {
  const { data } = await georgesPaymentApi({
    method: 'POST',
    url: '/restoration',
  });
  return data;
}

export async function getStripeCustomerData() {
  const { data } = await georgesPaymentApi({
    method: 'GET',
    url: '/',
  });
  return data.stripeCustomerData;
}

export async function getStripeCustomerInvoiceItems() {
  const { data } = await georgesPaymentApi({
    method: 'GET',
    url: '/invoice-items',
  });
  return data.stripeCustomerInvoiceItems;
}

export async function getStripeInvoicePaymentIntent({ stripePaymentIntentId }) {
  const { data } = await georgesPaymentApi({
    method: 'GET',
    url: `payment-intent/${stripePaymentIntentId}`,
  });
  return data.paymentIntent;
}

export async function getStripeInvoiceCharge({ stripeChargeId }) {
  const { data } = await georgesPaymentApi({
    method: 'GET',
    url: `charge/${stripeChargeId}`,
  });
  return data.charge;
}

export async function getSubscriptionData() {
  const {
    data: { subscriptionPlan, subscriptionTaxRate },
  } = await georgesPaymentApi({
    method: 'GET',
    url: `subscription`,
  });
  return { subscriptionPlan, subscriptionTaxRate };
}

export async function getStripeMandate() {
  const { data } = await georgesPaymentApi({
    method: 'GET',
    url: '/mandate',
  });
  return data.mandate;
}

export async function getSubscriptionPlanForTaxRegime({ taxRegime }) {
  const { data } = await georgesPaymentApi({
    url: `/subscription-plan/${taxRegime}`,
  });
  return data;
}
