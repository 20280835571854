import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import moment from 'moment';

import { sortedPeriodChoices } from '@/modules/documents/models/documents.model';
// Constants
import { tvaFrequenciesEnum } from '@/shared/constants/functional';

export function transformPeriodChoice(periodChoices) {
  return keyBy(
    periodChoices.map((period) => ({
      ...period,

      /**
       * "Exercice" : not always a full year or an entire year. It's a fiscal period.
       * "Année" : calendar year
       *
       * In BNC we always have a 1 year exercise, but in IS/BIC we can have a
       * 2 years exercise. If we have a DAS2 or tvaBuild on a year without a fiscal period
       * we display "Année" instead of "Exercice".
       */
      dropdownName: `Exercice ${period.id}`,
    })),
    'id',
  );
}

export function transformVatDeclarations(vatDeclarations) {
  const getCa3closingTotalLabel = (closingTotal) => (closingTotal < 0 ? 'Crédit de TVA' : 'TVA à payer');
  const getCa12closingTotalLabel = (closingTotal) => (closingTotal < 0 ? 'Solde excédentaire' : 'Solde à payer');

  return vatDeclarations.map((vatDeclaration) => {
    const getClosingTotalLabel = {
      monthly: getCa3closingTotalLabel,
      quarterly: getCa3closingTotalLabel,
      yearly: getCa12closingTotalLabel,
    }[vatDeclaration.vatFrequency];

    return {
      ...vatDeclaration,
      type: 'vatDeclaration',
      closingTotalLabel: getClosingTotalLabel && getClosingTotalLabel(vatDeclaration.closingTotal),
      sortDate: vatDeclaration.fiscalPeriod.end_date,
    };
  });
}

export function transformVatAdvancePaymentDeclarations(vatAdvancePaymentDeclarations) {
  return vatAdvancePaymentDeclarations.map((vatAdvancePaymentDeclaration) => ({
    ...vatAdvancePaymentDeclaration,
    _id: vatAdvancePaymentDeclaration.vatAdvancePaymentId,
    type: 'vatAdvancePaymentDeclaration',
    title: `Acompte de ${moment(vatAdvancePaymentDeclaration.fiscalPeriodId).format('MMMM YYYY')}`,
    closingTotalLabel: "Montant de l'acompte",
    sortDate: moment(vatAdvancePaymentDeclaration.declarationDate).toDate(),
  }));
}

export function transformVatBuildDate(vatBuildDate, vatFrequency) {
  const format = get(tvaFrequenciesEnum, [vatFrequency, 'datePickerFormat'], 'YYYY');
  return moment(vatBuildDate).format(format);
}

export function getPeriodChoicesFilteredByDocumentName({ documents, periodChoices, documentName }) {
  const document = documents?.[documentName];

  if (!document || !document.availablePeriods || document.availablePeriods.length === 0) {
    return sortedPeriodChoices({ periodChoices: periodChoices });
  }

  const filteredPeriodChoices = Object.fromEntries(
    Object.entries(periodChoices).filter(([key]) =>
      document.availablePeriods.some((documentAvailability) => documentAvailability.year === Number(key)),
    ),
  );

  return sortedPeriodChoices({ periodChoices: filteredPeriodChoices });
}
