<template>
  <GAppBar :class="classes" class="topbar" variant="outlined">
    <!-- Title / Subtitle -->
    <div class="topbar__title">
      <a class="topbar__title-link d-flex align-center" href="#" @click.prevent="goToParentPage">
        <GIcon v-if="hasParentPage" class="m-r-16" name="fal fa-chevron-left" />
        <GText tag="h3">{{ hasParentPage ? parentPageTitle : pageTitle }}</GText>
      </a>

      <span class="topbar__title-text text-h3">
        {{ pageTitle }}
      </span>
    </div>

    <!-- Search bar -->
    <div v-if="searchBar" class="topbar__search">
      <component :is="searchBar" />
    </div>

    <!-- Actions/Buttons -->
    <transition name="slideInOut" mode="out-in">
      <div v-if="actions" class="topbar__actions">
        <component v-for="(button, index) in actions" :key="index" :is="button.component" v-bind="button.props" />
      </div>
    </transition>
  </GAppBar>
</template>

<script>
import get from 'lodash/get';
import { mapGetters } from 'vuex';

import { getTitle } from '@/shared/middlewares/updateTitle';

const SETTINGS_PAGE = [
  'userSettingsStripe',
  'userSettingsAccounting',
  'userSettingsDeclarations',
  'userSettingsBanks',
  'userSettingsOptions',
];

const SETTINGS_PAGE_ONLY_MOBILE = ['support', 'referral'];

export default {
  name: 'TopNavbar',
  computed: {
    ...mapGetters({
      currentUser: 'user/currentUser',
    }),
    isMobile() {
      return this.$georgesuikit.breakpoint.isMobile;
    },
    classes() {
      return {
        'topbar--has-link': this.hasParentPage,
        'topbar--has-search': this.searchBarVisible,
      };
    },
    pageTitle() {
      if (!this.isMobile && SETTINGS_PAGE.includes(get(this.$route, 'name'))) {
        return this.parentPageTitle;
      }
      return getTitle({
        title: get(this.$route, 'meta.title'),
        user: this.currentUser,
        route: this.$route,
        isMobile: this.isMobile,
      });
    },
    hasParentPage() {
      if (!this.isMobile && get(this.$route, 'meta.parent.showParentLinkOnlyOnMobile')) return false;
      /* Referral page is under settings in mobile view */
      if (!this.isMobile && [...SETTINGS_PAGE, ...SETTINGS_PAGE_ONLY_MOBILE].includes(get(this.$route, 'name')))
        return false;
      return Boolean(get(this.$route, 'meta.parent'));
    },
    parentPageTo() {
      return get(this.$route, 'meta.parent.to');
    },
    parentPageTitle() {
      return get(this.$route, 'meta.parent.title');
    },
    parentPageLink() {
      return get(this.$route, 'meta.parent.link');
    },
    searchBar() {
      return get(this.$route, 'meta.searchBar');
    },
    searchBarVisible() {
      return Boolean(this.searchBar);
    },
    actions() {
      const actions = get(this.$route, 'meta.actions');

      if (!actions) {
        return;
      }

      return Object.fromEntries(
        Object.entries(actions).map(([key, value]) => {
          if (value.component) {
            return [key, value];
          }

          return [key, { component: value }];
        }),
      );
    },
  },
  methods: {
    goToParentPage() {
      if (this.parentPageTo) {
        this.$router.push(this.parentPageTo);
      } else {
        this.$router.push({ name: this.parentPageLink });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  display: grid;
  grid-template-areas: 'title actions';
  grid-template-columns: auto auto;
  grid-gap: 0 ($gutter * 2);
  align-items: center;
  padding: 0 ($gutter * 2);

  @include mq('md') {
    padding: 0 ($gutter * 3) 0;
  }

  @include mq(1100px) {
    grid-gap: 0 ($gutter * 4);
  }

  @include mq('xl') {
    padding: 0 ($gutter * 7);
  }

  @include mq($to: 897px, $and: '(orientation: landscape) and (-webkit-min-device-pixel-ratio: 3)') {
    padding: 0 ($gutter * 2);
  }

  // Elements
  &__title {
    position: relative;
    @include flex-align(column, flex-start, center);
    flex-grow: 1;

    height: 54px;
    overflow: hidden;
    order: 1;

    a,
    .topbar__title-text {
      letter-spacing: 0.23px;
      color: rgb(var(--g-theme-on-surface));
      transition: transform 0.25s ease-in-out, opacity 0.3s ease-in-out;
      transform-origin: 0 0;
      will-change: transform, opacity;
    }

    &-text {
      position: absolute;
      top: 28px;
      left: 0;
      opacity: 0;
      transform: translate3d(0, 26px, 0);
    }
  }

  &__search {
    position: relative;
    display: none;

    @include mq('md') {
      display: flex;
      align-items: center;
      justify-items: center;
      flex-grow: 1;
      order: 2;
    }
  }

  &__actions {
    position: relative;
    @include flex-align(row, center, flex-end);
    order: 2;

    @include mq('md') {
      order: 3;
    }

    button + div,
    div + button,
    div + div,
    .g-tooltip + div,
    :deep(button ~ button) {
      margin-left: $gutter;

      @include mq(400px) {
        margin-left: ($gutter * 2);
      }
    }
  }

  // Modifers
  &--has-link {
    .topbar__title {
      a {
        transform: translateY(-10px) scale(0.6);
        color: rgba(var(--g-theme-on-surface), var(--g-opacity-text-medium));
        cursor: pointer;
      }
      .topbar__title-text {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &--has-search {
    grid-template-areas: 'title actions';
    grid-template-columns: auto auto;

    @include mq('md') {
      grid-template-areas: 'title search actions';
      grid-template-columns: auto 1fr auto;
    }
  }
}
</style>
