import georgesApi from '@/shared/apis/georges';

const getUrl = (path) => `/activities/cfe-declaration${path}`;

export async function getCfeDeclarationActivity() {
  const { data } = await georgesApi({
    method: 'GET',
    url: getUrl(''),
  });

  return data;
}
