import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import moment from 'moment';

// Services
import {
  getDocuments,
  getPayslipDeclarationsOnPeriod,
  getPeriodChoices,
  getVatAdvancePaymentDeclarationsOnFiscalYear,
  getVatDeclarationsOnFiscalPeriod,
  getVatPeriodChoices,
} from '@/modules/documents/api/documents.api';
import { getD2571ForFiscalPeriod } from '@/modules/documents/components/D2571/D2571DocumentBox.service';
import {
  transformPayslipDeclarations,
  transformPayslipDeclarationsToPeriodChoices,
} from '@/modules/documents/components/Payslip/models/payslip.model';
import { getDocumentAvailability } from '@/modules/documents/models/documents.model';
// Model
import {
  getPeriodChoicesFilteredByDocumentName,
  transformPeriodChoice,
  transformVatAdvancePaymentDeclarations,
  transformVatDeclarations,
} from '@/modules/documents/models/periodChoice';

export const stateData = {
  documents: undefined,
  isFetchingDocuments: false,

  periodChoices: {},
  selectedPeriodId: undefined,

  // ---
  // VAT
  // ---
  vatPeriodChoices: {},
  vatDeclarationsToShow: [],
  selectedVatPeriodId: undefined,

  // ---
  // VAT advance payment
  // ---
  vatAdvancePaymentDeclarationsToShow: [],

  // ---
  // Payslip
  // ---
  payslipDeclarationsToShow: [],
  payslipPeriodChoices: {},
  selectedPayslipPeriodId: undefined,

  // ---
  // 2571
  // ---
  selectedD2571PeriodId: undefined,
  d2571PeriodChoices: [],
};

export const mutations = {
  setDocuments(state, { documents }) {
    state.documents = documents;
  },
  setIsFetchingDocuments(state, { isFetchingDocuments }) {
    state.isFetchingDocuments = isFetchingDocuments;
  },
  setPeriodChoices(state, { periodChoices }) {
    state.periodChoices = periodChoices;

    // Set selected by default
    if (!state.selectedPeriodId) {
      const selectedPeriodOnInit = find(state.periodChoices, ['isSelectedPeriodOnInit', true]);
      state.selectedPeriodId = selectedPeriodOnInit.id;
    }
  },
  setSelectedPeriod(state, { selectedPeriodId }) {
    state.selectedPeriodId = selectedPeriodId;
  },

  // ---
  // VAT
  // ---
  setClosingVatPeriodChoices(state, { vatPeriodChoices }) {
    state.vatPeriodChoices = vatPeriodChoices;
  },
  setVatDeclarations(state, { vatDeclarationsToShow }) {
    state.vatDeclarationsToShow = vatDeclarationsToShow;
  },
  setSelectedVatPeriodId(state, { vatPeriodId }) {
    state.selectedVatPeriodId = vatPeriodId;
  },

  // ---
  // VAT advance payment
  // ---
  setVatAdvancePaymentDeclarations(state, { vatAdvancePaymentDeclarationsToShow }) {
    state.vatAdvancePaymentDeclarationsToShow = vatAdvancePaymentDeclarationsToShow;
  },

  // ---
  // Payslip
  // ---
  setPayslipPeriodChoices(state, { payslipPeriodChoices }) {
    state.payslipPeriodChoices = payslipPeriodChoices;
  },
  setPayslipDeclarations(state, { payslipDeclarationsToShow }) {
    state.payslipDeclarationsToShow = payslipDeclarationsToShow;
  },
  setSelectedPayslipPeriodId(state, { fiscalPeriodId }) {
    state.selectedPayslipPeriodId = fiscalPeriodId;
  },

  // ---
  // 2571
  // ---
  setSelectedD2571PeriodId(state, { periodId }) {
    state.selectedD2571PeriodId = periodId;
  },
  setD2571PeriodChoices(state, { periods }) {
    state.d2571PeriodChoices = periods;
  },

  // -----------
  // Reset store
  // -----------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

export const getters = {
  documents: (state) => state.documents,
  getDocumentAvailability:
    (state) =>
    ({ documentName, fiscalYear }) => {
      return getDocumentAvailability({ documents: state.documents, documentName, fiscalYear });
    },
  getDocument:
    (state) =>
    ({ documentName }) => {
      return state.documents?.[documentName];
    },
  isFetchingDocuments: (state) => state.isFetchingDocuments,
  periodChoices: (state) => state.periodChoices,
  selectedPeriodId: (state) => state.selectedPeriodId,
  selectedPeriod: (state) => get(state.periodChoices, state.selectedPeriodId, {}),
  selectedPeriodText: (state) => get(state.periodChoices, [state.selectedPeriodId, 'dropdownName'], ''),
  isSelectedPeriodAnExercise: (state, { selectedPeriod }) => get(selectedPeriod, 'isExercise', false),
  selectedPeriodStartDate: (state, { selectedPeriod }) => selectedPeriod?.startDate,
  selectedPeriodEndDate: (state, { selectedPeriod }) => selectedPeriod?.endDate,
  isStartMonthJanuary: (state, { selectedPeriodStartDate }) => {
    if (!selectedPeriodStartDate) {
      return false;
    }
    return moment(selectedPeriodStartDate).month() === 0;
  },
  isEndMonthDecember: (state, { selectedPeriodEndDate }) => {
    if (!selectedPeriodEndDate) {
      return false;
    }
    return moment(selectedPeriodEndDate).month() === 11;
  },
  isPeriodTwelveMonths: (state, { selectedPeriodStartDate, selectedPeriodEndDate }) => {
    if (!selectedPeriodStartDate || !selectedPeriodEndDate) {
      return true;
    }
    return moment(selectedPeriodEndDate).diff(moment(selectedPeriodStartDate), 'months') + 1 === 12;
  },
  isExerciceClassique: (state, { isStartMonthJanuary, isEndMonthDecember, isPeriodTwelveMonths }) => {
    return isStartMonthJanuary && isEndMonthDecember && isPeriodTwelveMonths;
  },
  isExerciceDecale: (state, { isSelectedPeriodAnExercise, isExerciceClassique }) =>
    isSelectedPeriodAnExercise && !isExerciceClassique,

  // ---
  // VAT
  // ---
  vatPeriodChoices: (state) => state.vatPeriodChoices,
  vatPeriodsToShow: (state) => state.vatDeclarationsToShow,
  selectedVatPeriodId: (state) => state.selectedVatPeriodId,
  selectedVatPeriod: (state) => find(flatMap(state.vatPeriodChoices), { id: state.selectedVatPeriodId }) || {},
  selectedVatPeriodText: (state) => state.selectedVatPeriodId,

  // ---
  // VAT advance payment
  // ---
  vatAdvancePaymentDeclarationsToShow: (state) => state.vatAdvancePaymentDeclarationsToShow,

  // ---
  // Payslip
  // ---
  payslipPeriodChoices: (state) => state.payslipPeriodChoices,
  payslipPeriodsToShow: (state) => state.payslipDeclarationsToShow,
  selectedPayslipPeriodId: (state) => state.selectedPayslipPeriodId,

  // ---
  // 2571
  // ---
  selectedD2571PeriodId: (state) => state.selectedD2571PeriodId,
  d2571PeriodChoices: (state) => state.d2571PeriodChoices,
  selectedD2571Period: (state) => find(state.d2571PeriodChoices, { periodId: state.selectedD2571PeriodId }) || {},
  getPeriodChoicesFilteredByDocumentName:
    (state) =>
    ({ documentName }) => {
      return getPeriodChoicesFilteredByDocumentName({
        documents: state.documents,
        periodChoices: state.periodChoices,
        documentName,
      });
    },
};

export const actions = {
  fetchDocuments: async ({ commit }, { periodId }) => {
    commit('setIsFetchingDocuments', { isFetchingDocuments: true });
    try {
      const documents = await getDocuments({ periodId });
      commit('setDocuments', { documents: documents });
    } finally {
      commit('setIsFetchingDocuments', { isFetchingDocuments: false });
    }
  },
  fetchPeriodChoices: async ({ commit }) => {
    const { periodChoices } = await getPeriodChoices();

    // We build an object indexed on the ID to make this data easier to use on the frontend
    commit('setPeriodChoices', { periodChoices: transformPeriodChoice(periodChoices) });
  },
  setSelectedPeriodId({ commit }, { selectedPeriodId }) {
    commit('setSelectedPeriod', { selectedPeriodId });
  },

  // ---
  // VAT
  // ---
  async fetchVatPeriodChoices({ commit }) {
    const { vatPeriodChoices } = await getVatPeriodChoices();
    commit('setClosingVatPeriodChoices', { vatPeriodChoices });
  },
  async fetchVatDeclarationsForFiscalPeriod({ commit }, { fiscalYear }) {
    const { vatDeclarations } = await getVatDeclarationsOnFiscalPeriod({ fiscalYear });
    commit('setVatDeclarations', { vatDeclarationsToShow: transformVatDeclarations(vatDeclarations) });
  },
  setSelectedVatPeriodId({ commit }, { vatPeriodId }) {
    commit('setSelectedVatPeriodId', { vatPeriodId });
  },

  // ---
  // VAT advance payment
  // ---
  async fetchVatAdvancePaymentDeclarationsForFiscalPeriod({ commit }, { fiscalYear }) {
    const { vatAdvancePaymentDeclarations } = await getVatAdvancePaymentDeclarationsOnFiscalYear({ fiscalYear });
    commit('setVatAdvancePaymentDeclarations', {
      vatAdvancePaymentDeclarationsToShow: transformVatAdvancePaymentDeclarations(vatAdvancePaymentDeclarations),
    });
  },

  // ---
  // Payslip
  // ---
  async fetchPayslipPeriodChoices({ commit }) {
    const { payslipDeclarations } = await getPayslipDeclarationsOnPeriod();
    commit('setPayslipPeriodChoices', {
      payslipPeriodChoices: transformPayslipDeclarationsToPeriodChoices(payslipDeclarations),
    });
  },
  async fetchPayslipDeclarationsForFiscalPeriod({ commit }, { fiscalYear }) {
    const { payslipDeclarations } = await getPayslipDeclarationsOnPeriod({ fiscalYear });
    commit('setPayslipDeclarations', { payslipDeclarationsToShow: transformPayslipDeclarations(payslipDeclarations) });
  },
  setSelectedPayslipPeriodId({ commit }, { fiscalPeriodId }) {
    commit('setSelectedPayslipPeriodId', { fiscalPeriodId });
  },

  // ---
  // 2571
  // ---
  setSelectedD2571PeriodId({ commit }, { periodId }) {
    commit('setSelectedD2571PeriodId', { periodId });
  },
  async fetchD2571PeriodChoices({ commit }, { fiscalYear }) {
    const { periods } = await getD2571ForFiscalPeriod({ fiscalYear });
    const periodsWithAvailableDocument = periods.filter(({ isDocumentsAvailable }) => isDocumentsAvailable);
    commit('setD2571PeriodChoices', { periods: periodsWithAvailableDocument });
  },
};

export default {
  namespaced: true,
  state: { ...stateData },
  getters,
  actions,
  mutations,
};
