import get from 'lodash/get';

import { DOCUMENT_GROUPS, DOCUMENTS_CONFIG } from '../documents.constants';

export function sortedPeriodChoices({ periodChoices }) {
  if (!periodChoices) return [];

  return Object.entries(periodChoices)
    .sort(([aKey], [bKey]) => bKey - aKey)
    .reduce((acc, [, item]) => [...acc, item], []);
}

export function getDocumentGroupsItems({ documentsGroups = DOCUMENT_GROUPS, documentsToDisplay }) {
  return documentsGroups
    .filter(({ documents }) =>
      documents.some(({ key }) => documentsToDisplay.find(({ key: docKey }) => docKey === key)),
    )
    .map(({ key, name, icon, color }) => ({
      title: name,
      value: key,
      to: { name: 'documentGroup', params: { documentGroup: key } },
      avatar: {
        icon,
        color,
      },
    }));
}

export function getDocumentAvailability({ documents, documentName, fiscalYear }) {
  if (!documents) {
    return false;
  }

  if (documents?.[documentName]?.availablePeriods === undefined) {
    // if `availablePeriods` isn't defined, we consider it's the document box responsibility to check if the document is available
    return true;
  }

  return documents[documentName].availablePeriods.some(
    (documentAvailability) => documentAvailability.year === fiscalYear,
  );
}

export function getIsDocumentInEarlyPreview({ documents, documentName, fiscalYear }) {
  // if `availablePeriods` isn't defined, we consider that we are not in early preview
  if (documents?.[documentName]?.availablePeriods === undefined) {
    return false;
  }

  return documents[documentName].availablePeriods.some(
    (documentAvailability) => documentAvailability.year === fiscalYear && documentAvailability.isEarlyPreview,
  );
}

export function getDocumentsToDisplay({ documentsConfig = DOCUMENTS_CONFIG, documents, fiscalYear }) {
  const activeDocuments = documentsConfig
    .filter(({ key }) => get(documents, [key, 'isActive'], false))
    .map((document) => ({
      ...document,
      isAvailable: getDocumentAvailability({ documents, documentName: document.key, fiscalYear }),
      isEarlyPreview: getIsDocumentInEarlyPreview({ documents, documentName: document.key, fiscalYear }),
    }));

  // social declaration and d2042 are now combined in documents
  // so if the user has a social declaration, we should not display d2042 document box
  if (documents && Object.keys(documents).includes('socialDeclaration')) {
    return activeDocuments.filter((document) => document.key !== 'd2042');
  }

  return activeDocuments;
}

export function getDocumentParentGroupLink({ documentName }) {
  const documentGroup = DOCUMENT_GROUPS.find(({ documents }) => documents.some(({ key }) => key === documentName));

  return {
    title: documentGroup.name,
    to: { name: 'documentGroup', params: { documentGroup: documentGroup.key } },
  };
}
