import { InvoiceStatus } from '@georges-tech/facturation-model-lib';
import compact from 'lodash/compact';
import omit from 'lodash/omit';
import sum from 'lodash/sum';
import moment from 'moment';

import { clientName } from '@/modules/facturation/components/clients/clients.computed';
import { replaceUndefinedByNull } from '@/shared/utils/objects';

import { FACTURATION_TAB_ITEMS } from './facturation.constants';

export function formatInvoiceNumber({ date, invoiceNumber }) {
  if (!date) {
    return;
  }

  const datePrefix = moment(date).format('YYYYMM');

  return `${datePrefix}-${invoiceNumber}`;
}

export function formatInvoiceNumberDate({ date }) {
  return moment(date).format('YYYYMM');
}

export function blobUrlToFile(blobUrl, fileName) {
  return new Promise((resolve) => {
    fetch(blobUrl).then((res) => {
      res.blob().then((blob) => {
        const file = new File([blob], fileName, { type: blob.type });
        resolve(file);
      });
    });
  });
}

export function fileToBlobUrl({ file, maxImageWidth, maxImageHeight }) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onerror = reject;
    fileReader.onload = () => {
      const image = new Image();
      image.src = fileReader.result;
      image.onload = function () {
        const height = this.height;
        const width = this.width;
        if (height > maxImageHeight || width > maxImageWidth) {
          const error = new Error();
          error.code = 'DIMENSIONS_TOO_LARGE';
          reject(error);
        }
        resolve(fileReader.result);
      };
    };
    fileReader.readAsDataURL(file);
  });
}

export function getUserHasVat({ vatConfig }) {
  return vatConfig.tvaSelected !== 'tva_franchise_base' && vatConfig.tvaSelected !== 'tva_exonerated';
}

export function getInvoiceFilenameForDownload({ invoice }) {
  if (invoice.origin === 'INDY') {
    if (invoice.status === InvoiceStatus.RECEIPTED) {
      return `Facture-${invoice.fullInvoiceNumber}-acquittee.pdf`;
    }
    return `Facture-${invoice.fullInvoiceNumber}.pdf`;
  }
  if (invoice.origin === 'IMPORTED') {
    return `Facture-${invoice.fullInvoiceNumber}.pdf`;
  }
  return `Facture.pdf`;
}

export function getTabItems({ userHasEnabledRetailFeature }) {
  if (!userHasEnabledRetailFeature) {
    return Object.values(omit(FACTURATION_TAB_ITEMS, 'MONTHLY_RETAIL_REPORT'));
  }

  return Object.values(FACTURATION_TAB_ITEMS);
}

export function formatSoldeDraftPayload(soldeDraftPayload) {
  return replaceUndefinedByNull(soldeDraftPayload);
}

export function combineUserSources({ user, facturationUser, userHasVat, templateCustomInfoBuffer }) {
  const streetName = compact([user.profile?.address?.streetType, user.profile?.address?.streetName]).join(' ').trim();

  return {
    siret: facturationUser?.siret || user.profile?.siret,
    companyName: user.company_name,
    address: {
      city: facturationUser?.address?.city || user.profile?.address?.city,
      country: facturationUser?.address?.country || user.profile?.address?.country || 'France',
      zipCode: facturationUser?.address?.postalCode || user.profile?.address?.zipCode,
      streetName: facturationUser?.address?.streetName || streetName,
      streetNumber: facturationUser?.address?.streetName ? null : user.profile?.address?.streetNumber,
    },
    capitalSocial: facturationUser?.capitalSocial,
    departementImmatriculationRepertoireMetiers: facturationUser?.departementImmatriculationRepertoireMetiers,
    greffe: facturationUser?.greffe,
    legalForm: facturationUser?.legalForm,
    vatId: userHasVat ? facturationUser?.vatId : null,
    logoUrl: templateCustomInfoBuffer?.logoUrl,
    extraInformation: facturationUser?.extraInformation,
  };
}

export function getFormattedActiveFilters({ clientId, dates, search }, clients) {
  const client = clients.find(({ id }) => id === clientId);
  const [start, end] = dates ?? [];

  const isDateFiltered = start !== undefined && end !== undefined;
  const formattedFilters = {
    ...(client && { clientId: { formattedValue: clientName({ client }) } }),
    ...(isDateFiltered && {
      dates: { formattedValue: `${moment(start).format('DD MMMM YYYY')} ~ ${moment(end).format('DD MMMM YYYY')}` },
    }),
    ...(search && {
      search: { formattedValue: search },
    }),
  };

  return formattedFilters;
}

export function getFormattedFiltersForServerAPI(filters) {
  const [startDate, endDate] = filters.dates ?? [];
  return omit({ ...filters, startDate, endDate }, 'dates');
}

export function getGlobalCounts({ invoicesCounts, invoiceDrafts, quotations, quotationDrafts }) {
  const totalInvoicesCount = invoicesCounts ? sum(Object.values(invoicesCounts)) : 0;
  return {
    [FACTURATION_TAB_ITEMS.INVOICE.value]: totalInvoicesCount + invoiceDrafts.length,
    [FACTURATION_TAB_ITEMS.QUOTATION.value]: quotations.length + quotationDrafts.length,
  };
}

export const toServerEmailSendingOptions = ({ email }) => {
  return {
    isSentByEmail: email.isSentByEmail,
    senderName: email.senderName?.trim().length > 0 ? email.senderName : undefined,
    personalMessage: email.personalMessage?.trim().length > 0 ? email.personalMessage : undefined,
  };
};

export const toServerEmailRecipients = ({ email }) => {
  if (email.isSentByEmail && email.emailRecipients?.length > 0) {
    return email.emailRecipients;
  }
  return undefined;
};

export const toServerAddress = ({ address }) => {
  return {
    city: address.city,
    country: address.country,
    postalCode: address.zipCode,
    streetName: [address.streetNumber, address.streetName].filter(Boolean).join(' '),
  };
};

export function getFormattedTemplateCustomizationInfo(user) {
  return {
    logoUrl: user.logoUrl,
    templateCustomBackgroundColor: user.templateCustomBackgroundColor,
    templateCustomMainColor: user.templateCustomMainColor,
    templateFontFamily: user.templateFontFamily,
    headerImage: user.headerImage,
  };
}
