const settingsRoutes = [
  {
    path: '/votre-compte',
    name: 'userSettingsNav',
    component: () => import('@/modules/settings/components/SettingsNav/SettingsNav.vue'),
    meta: {
      title: 'Paramètres',
    },
  },
  {
    path: '/compte',
    name: 'compte',
    meta: {
      title: 'Compte',
      requiresAuth: true,
      updateIntercom: true,
    },
    component: () => import('@/modules/settings/Settings.vue'),
    children: [
      {
        path: 'abonnement',
        name: 'userSettingsStripe',
        component: () => import('@/modules/settings/components/subscription/SubscriptionPage.vue'),
        meta: {
          title: 'Abonnement',
          parent: {
            title: 'Paramètres',
            link: 'userSettingsNav',
          },
        },
      },
      {
        path: 'profil',
        name: 'userSettingsAccounting',
        component: () => import('@/modules/settings/components/AccountSettings/AccountSettings.vue'),
        meta: {
          title: 'Profil',
          parent: {
            title: 'Paramètres',
            link: 'userSettingsNav',
          },
        },
      },
      {
        path: 'declarations',
        name: 'userSettingsDeclarations',
        component: () => import('@/modules/settings/components/DeclarationsSettings/DeclarationsSettings.vue'),
        meta: {
          title: 'Déclarations',
          parent: {
            title: 'Paramètres',
            link: 'userSettingsNav',
          },
        },
      },
      {
        path: 'banques',
        name: 'userSettingsBanks',
        component: () => import('@/modules/settings/components/ManageBankAccounts/ManageBankAccounts.vue'),
        meta: {
          title: 'Banques',
          parent: {
            title: 'Paramètres',
            link: 'userSettingsNav',
          },
        },
      },
      {
        path: 'options',
        name: 'userSettingsOptions',
        component: () => import('@/modules/settings/components/Options/Options.vue'),
        meta: {
          title: 'Options',
          parent: {
            title: 'Paramètres',
            link: 'userSettingsNav',
          },
        },
      },
      {
        path: 'securite',
        name: 'securitySettings',
        component: () => import('@/modules/settings/components/Security/SecuritySettings.vue'),
        meta: {
          title: 'Sécurité',
          parent: {
            title: 'Paramètres',
            link: 'userSettingsNav',
          },
        },
      },
    ],
  },
];

const invoiceRoute = {
  path: '/facture/:displayNumber',
  component: () => import('@/shared/layouts/BlankLayout/BlankLayout.vue'),
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: '',
      name: 'invoice',
      component: () => import('@/modules/settings/components/Invoice/Invoice.vue'),
      meta: {
        hideImpersonateRibbon: true,
      },
    },
  ],
};

export { invoiceRoute, settingsRoutes };
