import { useStore } from 'vuex';

import { isActivitiesPageVisible, isPresentationActivitiesPageVisible } from '@/modules/user/product/product.helpers';

import { ACTIVITIES_PREVIEW_ROUTE_NAME, ACTIVITIES_ROUTE_NAME } from '../constants/activities.constants';

export async function newActivitiesGuard({ to = {}, next }) {
  const store = useStore();

  const user = store.getters['user/currentUser'];
  const canUserSeeActivitiesPreview = isPresentationActivitiesPageVisible({ user });
  const canUserSeeActivities = isActivitiesPageVisible({ user });

  if (!canUserSeeActivitiesPreview && !canUserSeeActivities) {
    return next({ name: 'home', replace: true });
  } else if (canUserSeeActivitiesPreview && to.name !== ACTIVITIES_PREVIEW_ROUTE_NAME) {
    return next({ name: ACTIVITIES_PREVIEW_ROUTE_NAME, replace: true });
  } else if (canUserSeeActivities && to.name !== ACTIVITIES_ROUTE_NAME) {
    return next({ name: ACTIVITIES_ROUTE_NAME, replace: true });
  }

  return next();
}
