import { InvoiceStatus } from '@georges-tech/facturation-model-lib';

import georgesApi from '@/shared/apis/georges.js';
import { replaceUndefinedByNull } from '@/shared/utils/objects';

import { formatSoldeDraftPayload } from './facturation.model';

export {
  attachInvoiceToSale,
  countInvoices,
  createAcompteDraft,
  createClient,
  createCreditNoteDraftFromIndyInvoice,
  createDraftFromIndyInvoice,
  createIndyInvoice,
  createInvoiceDraftFromQuotation,
  createQuotation,
  createQuotationDraftFromQuotation,
  createQuotationDraftFromQuotationDraft,
  createSaleFromInvoice,
  createSoldeDraftFromQuotation,
  deleteClient,
  deleteDraft,
  deleteImportedInvoice,
  deleteProduct,
  deleteQuotation,
  deleteQuotationDraft,
  duplicateDraft,
  fetchUser,
  getDatePresets,
  getHeaderImageUrl,
  getInvoiceableAmountTTC,
  getInvoiceDocument,
  getInvoicesKpis,
  getLatestDraftOptions,
  getProducts,
  getQuotationDocument,
  getReceiptedLatestInformation,
  getVatConfig,
  listAllInvoices,
  listClients,
  listDepartementsOptions,
  listGreffeOptions,
  listInvoiceDrafts,
  listPaidInvoices,
  listPendingInvoices,
  listQuotationDrafts,
  listQuotations,
  receiptInvoice,
  removeUserLogo,
  saveProduct,
  saveUserLogo,
  setInvoiceStatus,
  setQuotationStatus,
  updateAcompteDraft,
  updateClient,
  updateSoldeDraft,
  updateUser,
  updateUserCustomizationInfo,
  uploadHeaderImage,
};

async function listClients() {
  const { data } = await georgesApi({
    url: `/facturation/clients`,
    method: 'get',
  });

  return data.clients;
}

async function fetchUser() {
  const { data } = await georgesApi({
    url: `/facturation/users/me`,
    method: 'get',
  });

  return data.user;
}

async function updateClient({ clientId, client }) {
  const { data } = await georgesApi({
    url: `/facturation/clients/${clientId}`,
    method: 'put',
    data: client,
  });
  return data.client;
}

async function createClient({ client }) {
  const { data } = await georgesApi({
    url: `/facturation/clients`,
    method: 'post',
    data: client,
  });

  return data.client;
}

async function listInvoiceDrafts(filters) {
  const { data } = await georgesApi({
    url: `/facturation/drafts`,
    method: 'get',
    params: {
      clientId: filters.clientId,
      startDate: filters.startDate,
      endDate: filters.endDate,
      search: filters.search,
    },
  });

  return data.drafts;
}

async function listQuotationDrafts(filters) {
  const { data } = await georgesApi({
    url: `/facturation/quotation-drafts`,
    method: 'get',
    params: {
      clientId: filters.clientId,
      startDate: filters.startDate,
      endDate: filters.endDate,
      search: filters.search,
    },
  });

  return data;
}

async function listQuotations(filters) {
  const { data } = await georgesApi({
    url: `/facturation/quotations`,
    method: 'get',
    params: {
      clientId: filters.clientId,
      startDate: filters.startDate,
      endDate: filters.endDate,
      search: filters.search,
    },
  });

  return data;
}

async function countInvoices(filters) {
  const { data } = await georgesApi({
    url: `/facturation/invoices/count`,
    method: 'get',
    params: {
      clientId: filters.clientId,
      startDate: filters.startDate,
      endDate: filters.endDate,
      search: filters.search,
    },
  });

  return data.counts;
}

// TODO: To remove when refacto done
// we need to avoid the usage of allInvoices to pick only the wanted ones and avoid big db requests
async function listAllInvoices() {
  const { data } = await georgesApi({
    url: `/facturation/invoices`,
    method: 'get',
  });

  return data.invoices;
}

async function listPaidInvoices({ start, limit, filters }) {
  const { data } = await georgesApi({
    url: `/facturation/invoices`,
    method: 'get',
    params: {
      start,
      limit,
      status: [InvoiceStatus.PAID, InvoiceStatus.RECEIPTED],
      clientId: filters.clientId,
      startDate: filters.startDate,
      endDate: filters.endDate,
      search: filters.search,
    },
  });

  return data.invoices;
}

async function listPendingInvoices({ start, limit, filters }) {
  const { data } = await georgesApi({
    url: `/facturation/invoices`,
    method: 'get',
    params: {
      start,
      limit,
      status: InvoiceStatus.PENDING,
      clientId: filters.clientId,
      startDate: filters.startDate,
      endDate: filters.endDate,
      search: filters.search,
    },
  });

  return data.invoices;
}

async function deleteProduct({ productId }) {
  return georgesApi({
    url: `/facturation/products/${productId}`,
    method: 'delete',
  });
}

async function deleteDraft({ draftId }) {
  return georgesApi({
    url: `/facturation/drafts/${draftId}`,
    method: 'delete',
  });
}

async function deleteClient({ clientId }) {
  return georgesApi({
    url: `/facturation/clients/${clientId}`,
    method: 'delete',
  });
}

async function deleteQuotationDraft({ quotationDraftId }) {
  return georgesApi({
    url: `/facturation/quotation-drafts/${quotationDraftId}`,
    method: 'delete',
  });
}

async function deleteImportedInvoice({ invoiceId }) {
  return georgesApi({
    url: `/facturation/imported-invoices/${invoiceId}`,
    method: 'delete',
  });
}

async function deleteQuotation({ quotationId }) {
  return georgesApi({
    url: `/facturation/quotations/${quotationId}`,
    method: 'delete',
  });
}

async function listGreffeOptions() {
  const { data } = await georgesApi({ method: 'get', url: '/facturation/drafts/greffes' });

  return data.greffeOptions;
}

async function listDepartementsOptions() {
  const { data } = await georgesApi({ method: 'get', url: '/facturation/drafts/departements' });

  return data.departementsOptions;
}

async function setInvoiceStatus(invoiceId, status) {
  await georgesApi({
    url: `/facturation/invoices/${invoiceId}/status`,
    method: 'post',
    data: {
      status,
    },
  });
}

async function getInvoiceDocument(invoiceId) {
  const { data } = await georgesApi({
    url: `/facturation/invoices/${invoiceId}/documents`,
    method: 'get',
    responseType: 'arraybuffer',
  });

  return data;
}

async function getInvoicesKpis(filters) {
  const { data } = await georgesApi({
    url: `/facturation/invoices/kpis`,
    method: 'get',
    params: {
      clientId: filters.clientId,
      startDate: filters.startDate,
      endDate: filters.endDate,
      search: filters.search,
    },
  });

  return data;
}

async function getProducts() {
  const { data } = await georgesApi({
    url: '/facturation/products',
    method: 'get',
  });

  return data;
}

async function duplicateDraft({ draftId }) {
  const { data } = await georgesApi({
    url: `/facturation/drafts/${draftId}/duplicate`,
    method: 'GET',
  });

  return data.draft;
}

async function createDraftFromIndyInvoice({ invoiceId }) {
  const { data } = await georgesApi({
    url: `/facturation/invoices/${invoiceId}/to-draft`,
    method: 'GET',
  });

  return data.draft;
}

async function createSoldeDraftFromQuotation({ quotationId }) {
  const { data } = await georgesApi({
    url: `/facturation/quotations/${quotationId}/to-solde-draft`,
    method: 'POST',
  });

  return data;
}

async function createCreditNoteDraftFromIndyInvoice({ invoiceId }) {
  const { data } = await georgesApi({
    url: `facturation/credit-note-invoices/draft`,
    method: 'POST',
    params: { fromInvoiceId: invoiceId },
  });

  return data.draft;
}

async function getQuotationDocument(quotationId) {
  const { data } = await georgesApi({
    url: `/facturation/quotations/${quotationId}/documents/quotation`,
    method: 'get',
    responseType: 'arraybuffer',
  });

  return data;
}

async function setQuotationStatus(quotationId, status) {
  await georgesApi({
    url: `/facturation/quotations/${quotationId}/status`,
    method: 'post',
    data: {
      status,
    },
  });
}

async function createInvoiceDraftFromQuotation({ quotationId }) {
  const { data } = await georgesApi({
    url: `/facturation/quotations/${quotationId}/to-invoice-draft`,
    method: 'GET',
  });

  return data;
}

async function createQuotation({ quotationDraftId, quotationNumber }) {
  const { data } = await georgesApi({
    url: `/facturation/indy-quotations`,
    method: 'post',
    data: {
      quotationDraftId,
      quotationNumber,
    },
  });

  return data;
}

async function createQuotationDraftFromQuotation({ quotationId }) {
  const { data } = await georgesApi({
    url: `/facturation/quotations/${quotationId}/to-quotation-draft`,
    method: 'POST',
  });

  return data;
}

async function createQuotationDraftFromQuotationDraft({ quotationDraftId }) {
  const { data } = await georgesApi({
    url: `/facturation/quotation-drafts/${quotationDraftId}/to-quotation-draft`,
    method: 'POST',
  });

  return data;
}

async function getLatestDraftOptions() {
  const { data } = await georgesApi({
    method: 'get',
    url: '/facturation/latest-edited-draft-options',
  });
  return data;
}

async function createSaleFromInvoice({ invoiceId }) {
  const { data } = await georgesApi({
    method: 'POST',
    url: `/sales/invoices/${invoiceId}`,
  });
  return data;
}

async function attachInvoiceToSale({ invoiceId }) {
  const { data } = await georgesApi({
    method: 'POST',
    url: `/sales/admin/sales/invoices/${invoiceId}/attach`,
  });
  return data;
}

async function getVatConfig({ date }) {
  const { data } = await georgesApi({
    url: `/vat/configuration`,
    params: { date },
  });
  return data;
}

async function updateUser({ payload }) {
  const { data } = await georgesApi({
    method: 'post',
    url: `/facturation/users/me`,
    data: replaceUndefinedByNull(payload),
  });

  return data;
}

async function saveUserLogo({ logo }) {
  const formData = new FormData();

  formData.append('logo', logo);

  await georgesApi({
    url: `/facturation/users/me/logo`,
    method: 'post',
    data: formData,
  });
}

async function removeUserLogo() {
  await georgesApi({
    url: `/facturation/users/me/logo`,
    method: 'delete',
  });
}

async function updateUserCustomizationInfo(templateCustomizationInfo) {
  const formattedCustomizationData = {
    templateFontFamily: templateCustomizationInfo.templateFontFamily,
    headerImage: templateCustomizationInfo.headerImage,
    templateCustomBackgroundColor: '#000000', // TODO CUSTOMIZATIONV2: put dynamic value when implemented
    templateCustomMainColor: '#FFFFFF', // TODO CUSTOMIZATIONV2: put dynamic value when implemented
  };

  const { data } = await georgesApi({
    url: `/facturation/customization`,
    method: 'put',
    data: formattedCustomizationData,
  });
  return data;
}

async function uploadHeaderImage({ headerImage }) {
  const formData = new FormData();

  formData.append('header', headerImage);

  const { data } = await georgesApi({
    url: `/facturation/customization/header`,
    method: 'post',
    data: formData,
  });
  return data;
}

async function getHeaderImageUrl() {
  const { data } = await georgesApi({
    url: `/facturation/customization/header`,
    method: 'get',
  });
  return data;
}

async function saveProduct({ product }) {
  return georgesApi({
    method: 'post',
    url: '/facturation/products',
    data: product,
  });
}

async function createAcompteDraft({ acompteDraft }) {
  const { data } = await georgesApi({
    method: 'post',
    url: '/facturation/drafts/acomptes',
    data: replaceUndefinedByNull(acompteDraft),
  });

  return data;
}

async function updateAcompteDraft({ invoiceDraftId, acompteDraft }) {
  return georgesApi({
    method: 'put',
    url: `/facturation/drafts/acomptes/${invoiceDraftId}`,
    data: replaceUndefinedByNull(acompteDraft),
  });
}

async function createIndyInvoice({ draftId, invoiceNumber, date }) {
  const { data } = await georgesApi({
    url: `/facturation/indy-invoices`,
    method: 'post',
    data: {
      draftId,
      invoiceNumber,
      date,
    },
  });

  return data;
}

async function getInvoiceableAmountTTC({ quotationId }) {
  const { data } = await georgesApi({
    url: `/facturation/quotations/${quotationId}/invoiceable-amount`,
    method: 'get',
  });

  return data;
}
async function updateSoldeDraft({ invoiceDraftId, soldeDraft: soldeDraftPayload }) {
  const { data } = await georgesApi({
    method: 'put',
    url: `/facturation/drafts/soldes/${invoiceDraftId}`,
    data: formatSoldeDraftPayload(soldeDraftPayload),
  });

  return data;
}

async function getDatePresets() {
  const { data } = await georgesApi({
    url: `/facturation/filter/date-presets`,
    method: 'get',
  });

  return data;
}

async function receiptInvoice({ invoiceId, receiptDate, paymentMethods, paymentReference, signature }) {
  const { data } = await georgesApi({
    url: `/facturation/invoices/${invoiceId}/receipted`,
    method: 'post',
    data: {
      receiptDate,
      paymentMethods,
      paymentReference,
      signature,
    },
  });
  return data;
}

async function getReceiptedLatestInformation() {
  const { data } = await georgesApi({
    url: `/facturation/invoices/receipted-latest-information`,
    method: 'get',
  });

  return data;
}
