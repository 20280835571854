import get from 'lodash/get';

export const SETTINGS_CALENDLY = get(window, 'GEORGES_SETTINGS.calendly', {});
export const SETTINGS_BACKOFFICE_URL = get(window, 'GEORGES_SETTINGS.backoffice.url');

export const SETTINGS_ENVIRONMENT = get(window, 'GEORGES_SETTINGS.environment', 'production');
export const ENVIRONMENTS = {
  DEVELOPMENT: 'development',
  PREPRODUCTION: 'preproduction',
  PRODUCTION: 'production',
  TEST: 'test',
};
