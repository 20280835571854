import get from 'lodash/get';
import moment from 'moment';

import { getCfeDeclarationActivity } from '@/modules/closing/cfeDeclarationActivity/cfeDeclarationActivity.service';
import generateStepsForAnActivity from '@/modules/newActivities/models/generateStepsForAnActivity';

const stateData = {
  loading: true,
  closingState: {},
  defaultSteps: {
    avisCfe: {
      title: 'Retrouvez votre avis de CFE',
      shortTitle: 'Retrouvez votre avis de CFE',
    },
    reportCfe: {
      title: 'Reportez votre imposition CFE',
      shortTitle: 'Report avis CFE',
    },
    validation: {
      title: 'Récapitulatif',
      shortTitle: 'Récapitulatif',
      isValidatingStep: true,
    },
  },
};

const getters = {
  loading: ({ loading }) => loading,
  getClosingState: ({ closingState }) => closingState,
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setClosingState(state, { closingState }) {
    state.closingState = closingState;
  },
  // ------------------------
  // --- Reset store --------
  // ------------------------
  resetStateData(state) {
    Object.assign(state, stateData);
  },
};

const actions = {
  async getClosingStateSteps({ state, rootGetters, dispatch }, { activity, user }) {
    if (!activity.isNextOccurrence) await dispatch('refreshCurrent');

    const showAdminInfo = rootGetters['user/showAdminInfo'];
    const { defaultSteps, closingState } = state;
    const userCurrentFiscalYear = rootGetters['user/currentFiscalYear'];
    return generateStepsForAnActivity({
      steps: defaultSteps,
      closingStateSteps: get(closingState, 'steps', {}),
      stepProperties: {
        minDateToClose: moment(activity.date?.minDate),
        isInEarlyClosing: showAdminInfo,
        fiscalPeriodId: String(userCurrentFiscalYear),
        periodId: closingState.periodId,
        closingState,
      },
      user,
      activity,
    });
  },
  async refreshCurrent({ commit }) {
    commit('setLoading', true);
    const closingState = await getCfeDeclarationActivity();
    commit('setClosingState', { closingState });
    commit('setLoading', false);
  },
};

export default {
  namespaced: true,
  state: {
    ...stateData,
  },
  getters,
  mutations,
  actions,
};
